<template>
  <div>
    <div v-if="!open" style="color: #8c939d">未开启钱包，请前往<a class="goto-home" @click="goto_home">首页</a>开启</div>
    <div class="pay" v-if="open">
      <el-card :body-style="{ padding: '0px' }" class="pay_card">
        <div class="img_style">
          <img src="@/assets/images/weixin.png" class="wx_image" />
        </div>
        <div class="card_button">
          <div style="padding:14px;">
            <el-button round type="success" @click="openTopUpDialog">充值</el-button>
            <el-button round @click="deductions">提现</el-button>
          </div>
        </div>
      </el-card>
      <el-card :body-style="{ padding: '0px' }" class="pay_card">
        <div class="img_style">
          <img src="@/assets/images/zhifubao.png" class="zfb_image" />
        </div>
        <div class="card_button">
          <div style="padding:14px;">
            <el-button round type="primary" @click="openTopUpDialog">充值</el-button>
            <el-button round @click="deductions">提现</el-button>
          </div>
        </div>
      </el-card>
    </div>
    <!--充值-->
    <div>
      <el-dialog title="充值" :visible.sync="dialogTopUpVisible" width="25%" center>
        <div class="top_up">
          <el-input v-model="top_up_value" placeholder="请输入充值金额">
            <template slot="append">元</template>
          </el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogTopUpVisible = false">取 消</el-button>
          <el-button type="primary" @click="topUp()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!--提现-->
    <div>
      <el-dialog title="提现" :visible.sync="dialogWithdrawDepositVisible" width="25%" center>
        <div>当前可用余额为:{{ balance }}</div>
        <div class="top_up">
          <el-input v-model="withdraw_deposit_value" placeholder="请输入提现金额">
            <template slot="append">元</template>
          </el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogWithdrawDepositVisible = false">取 消</el-button>
          <el-button type="primary" @click="withdraw_deposit()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PayAccount',
  data() {
    return {
      balance: '',
      open: true,
      top_up_value: '',
      withdraw_deposit_value: '',
      dialogTopUpVisible: false,
      dialogWithdrawDepositVisible: false
    }
  },
  created() {
    this.getWalletInfo()
  },
  methods: {
    withdraw_deposit() {
      this.$api.account.deductions({ deductions_value: this.withdraw_deposit_value }).then(res => {
        if (res.data.code === 200) {
          this.$message.success({
            message: res.data.msg,
            center: true,
            duration: 2000
          })
        } else {
          this.$message.error({
            message: res.data.msg,
            center: true,
            duration: 5000
          })
        }
        this.dialogWithdrawDepositVisible = false
      })
    },
    getBalance() {
      this.$api.account.getWalletInfo().then(res => {
        this.balance = res.data.data.balance
      })
    },
    deductions() {
      this.balance = ''
      this.dialogWithdrawDepositVisible = true
      this.getBalance()
    },
    goto_home() {
      this.$router.push('/')
    },
    openTopUpDialog() {
      this.top_up_value = ''
      this.dialogTopUpVisible = true
    },
    topUp() {
      this.$api.account.topUp({ top_up_value: this.top_up_value }).then(res => {
        if (res.data.code === 200) {
          this.$message.success({
            message: '充值成功',
            center: true,
            duration: 2000
          })
        } else {
          this.$message.error({
            message: res.data.msg,
            center: true,
            duration: 5000
          })
        }
        this.top_up_value = ''
        this.dialogTopUpVisible = false
      })
    },
    getWalletInfo() {
      this.$api.account.getWalletInfo().then(res => {
        if (res.data.code === 200) {
          this.open = true
        } else {
          this.$message.error({
            message: res.data.msg,
            center: true,
            duration: 2000
          })
          this.open = false
        }
      })
    }
  }
}
</script>

<style scoped>
.zfb_image {
  height: 40px;
  width: 114px;
  display: block;
}
.wx_image {
  height: 44px;
  width: 157px;
  display: block;
}
.pay_card {
  height: 150px;
  width: 300px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-content: space-between;
}
.img_style {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
.card_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.pay {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.top_up {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.goto-home {
  color: #33aef0;
}
</style>
